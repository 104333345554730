import type {
  DayRedBlackParams,
  DayRedBlackResponse,
  HotSeriesResponse,
  RedBlackResponse,
  SeriesComprehensiveTopResponse,
} from './types'
import type { FetchResponseType, PaginationParams, PaginationResponse } from '@/server/request/types'
import { useApiFetch } from '@/server/request'

export async function getWeekRedBlackApi() {
  return useApiFetch<FetchResponseType<RedBlackResponse>>('/webportal/base/home/weekTop', {
    method: 'GET',
  })
}
export async function getDayRedBlackApi(params: DayRedBlackParams) {
  return useApiFetch<FetchResponseType<DayRedBlackResponse[]>>('/webportal/base/top/week/day', {
    method: 'GET',
    params,
  })
}

export async function getRedBlackWeekYearListApi() {
  return useApiFetch<FetchResponseType<{ year: string }[]>>('/webportal/base/yearTop', {
    method: 'GET',
  })
}

export async function getRedBlackWeekListApi(params: PaginationParams & { year: string }) {
  return useApiFetch<FetchResponseType<PaginationResponse<RedBlackResponse[]>>>('/webportal/base/top/week/page', {
    method: 'GET',
    params,
  })
}

export async function getRedBlackDayListApi(params: PaginationParams) {
  return useApiFetch<FetchResponseType<PaginationResponse<DayRedBlackResponse[]>>>('/webportal/base/top/day/page', {
    method: 'GET',
    params,
  })
}

export async function seriesComprehensiveTopApi() {
  return useApiFetch<FetchResponseType<SeriesComprehensiveTopResponse>>('/webportal/base/wom/seriesComprehensiveTop', {
    method: 'GET',
  })
}

export async function getHotSeriesRankApi() {
  return useApiFetch<FetchResponseType<HotSeriesResponse[]>>('/webportal/base/ranking/seriesTop', {
    method: 'GET',
  })
}
